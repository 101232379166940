import { VehicleCollisionSelector } from '@/app/features/collisions/components/vehicle-collision-selector';
import { CollisionAreaFormData } from '@/app/features/collisions/components/vehicle-collision-selector/types';
import {
	createCollisionSelection,
	createCollisionSeverities
} from '@/app/features/collisions/components/vehicle-collision-selector/utils';
import { useUpdateCollisions } from '@/app/features/collisions/hooks/use-update-collisions';
import { withSignedIn } from '@/app/hoc/with-access';
import { useAnalytics } from '@/app/hooks/use-analytics';
import { useMeasurement } from '@/app/hooks/use-measure';
import { useUnsavedChanges } from '@/app/hooks/use-unsaved-changes';
import { JobSubActions } from '@/app/pages/job-detail';
import { Alert, Button } from '@mantine/core';
import { jobsQueries } from '@sdk/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { RepairApp } from '@partly/analytics';

type PageParams = {
	jobId: string;
};

const FORM_ID = 'collision-form';

const CollisionMapPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required job id parameter');
	}

	const navigate = useNavigate();
	const { value: nav } = useMeasurement('navigation-bar');
	const { logEvent } = useAnalytics();
	const [{ data: jobData }, { data: collisionMapData, isError: isUnsupportedBodyType }] =
		useSuspenseQueries({
			queries: [jobsQueries.get({ jobId }), jobsQueries.getCollisionMap({ jobId })]
		});

	const { mutateAsync: updateJob } = useUpdateCollisions();

	const { control, watch, formState, handleSubmit, reset } = useForm<CollisionAreaFormData>({
		defaultValues: createCollisionSelection(jobData.job.collisions, jobData.job.addedPartsCount > 0)
	});

	const selection = watch();

	const { isDirty, isSubmitting } = formState;

	const hasCollisions = useMemo(() => createCollisionSeverities(selection).length > 0, [selection]);

	const onSubmit = async (data: CollisionAreaFormData) => {
		const collisions = createCollisionSeverities(data);
		if (isDirty) {
			await updateJob({ jobId, collisions });
			logEvent(
				RepairApp.collision_selection.collisions_finalised({
					collision_map_id: collisionMapData.collisionMap.id,
					collision_map_name: collisionMapData.collisionMap.description,
					collisions_count: collisions.length
				})
			);
		}

		if (collisions.length !== 0) {
			navigate(`/job/${jobId}/parts`);
		} else {
			// retain changes
			reset(undefined, { keepValues: true });
		}
	};

	useUnsavedChanges(isDirty && !isSubmitting);

	return (
		<form
			id={FORM_ID}
			className="flex flex-col items-center justify-center px-4 pb-2"
			onSubmit={handleSubmit(onSubmit)}
			style={{
				height: `calc(100dvh - ${nav?.height ?? 0}px - 1rem)`
			}}
		>
			<JobSubActions>
				<Button
					key="collision-submit"
					type="submit"
					form={FORM_ID}
					disabled={!hasCollisions && !isDirty && !isSubmitting}
				>
					{hasCollisions ? (isDirty ? 'Save & Next' : 'Next') : 'Save'}
				</Button>
			</JobSubActions>
			<div className="grid place-items-center w-full h-full flex-1 p-4 rounded-xl border">
				<VehicleCollisionSelector
					className="max-h-[550px]"
					collisionMapId={collisionMapData.collisionMap.id}
					collisionAreas={collisionMapData.collisionMap.collisionAreas}
					control={control}
				/>
				{isUnsupportedBodyType && (
					<div className="grid flex-1 place-items-center">
						<Alert color="red" className="mt-4" title="Unsupported vehicle">
							Vehicle of this body style is currently not supported.
						</Alert>
					</div>
				)}
			</div>
		</form>
	);
};

export default withSignedIn(CollisionMapPage);
