import { BasicTable } from '@/app/molecules/order-table';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { memo } from 'react';
import { useOrderRequestTableItems } from '../../hooks/use-order-request-table-items';
import { OrderRequestModel } from '../../models';
import { draftOrderColumnBuilder } from '../../table-builder';
import { OrderRequestSection } from '../order-request-section';
import { InheritableElementProps } from '@/types/utilties';
import { tlsx } from '@/app/utils/tw-merge';

type CancelledOrderRequestProps = InheritableElementProps<
	'section',
	{
		model: OrderRequestModel;
	}
>;

const builder = draftOrderColumnBuilder();
const tableColumns = [
	builder.quantity(),
	builder.name(),
	builder.status(),
	builder.grade(),
	builder.price()
];

export const CancelledOrderRequest = memo(
	({ model, className, ...rest }: CancelledOrderRequestProps) => {
		const { items, subtotal } = useOrderRequestTableItems(model.items);

		const table = useReactTable({
			data: items,
			columns: tableColumns,
			getCoreRowModel: getCoreRowModel()
		});

		return (
			<OrderRequestSection className={tlsx('bg-gray-50', className)} {...rest}>
				<OrderRequestSection.Content className="flex items-center justify-between border-b">
					<div className="flex items-center gap-2">
						<XCircleIcon className="w-5 h-5 text-red-900" />
						<span className="font-semibold text-gray-900">Order cancelled</span>
					</div>
					{model.updated_at && (
						<span className="text-sm text-gray-700">
							Cancelled at {formatDate(model.updated_at, 'dd/MM, hh:mm a')}
						</span>
					)}
				</OrderRequestSection.Content>
				<OrderRequestSection.Content>
					<OrderRequestSection.Title>
						{model.vendor.Partner.name} (#{model.order_id})
					</OrderRequestSection.Title>
					<BasicTable className="mt-3" table={table} />
					<BasicTable.Subtotal subtotal={subtotal} />
				</OrderRequestSection.Content>
			</OrderRequestSection>
		);
	}
);
