import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import { Section } from '@/app/templates/page-section';
import { tlsx } from '@/app/utils/tw-merge';
import { jobsQueries } from '@/sdk/react';
import { bmsMutations } from '@/sdk/react/mutations/bms';
import { InheritableElementProps } from '@/types/utilties';
import { Button } from '@mantine/core';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

export const JobImageGrid = ({
	jobId,
	className
}: InheritableElementProps<'section', { jobId: string }>) => {
	const client = useQueryClient();

	const { data: jobData } = useSuspenseQuery({
		...jobsQueries.get({ jobId }),
		retryOnMount: false
	});

	const { mutateAsync: syncJobImages, isPending } = useMutation({
		...bmsMutations.syncJobImages,
		onSuccess: () => {
			client.invalidateQueries(jobsQueries.get({ jobId }));
		}
	});

	return (
		<Section className={tlsx('bg-white p-6 border rounded-md', className)}>
			<div className="flex items-center justify-between">
				<Section.Title className="text-lg">Images</Section.Title>
				<Button
					size="sm"
					variant="default"
					onClick={() => syncJobImages({ jobId })}
					loading={isPending}
					disabled={!jobData.job.bmsIntegrated}
					leftIcon={<ArrowPathIcon className="w-4 h-4" />}
				>
					Sync from iBodyShop
				</Button>
			</div>
			{jobData.job.images.length > 0 ? (
				<div className="grid grid-cols-3 gap-2 mt-4">
					{jobData.job.images.map(image => (
						<a
							key={image.original}
							className="col-span-1 aspect-square bg-gray-50 rounded-md"
							href={image.original}
							target="_blank"
							rel="noopener noreferrer"
						>
							<ImageWithSkeleton
								className="object-cover w-full aspect-square rounded"
								src={image.original}
								loading="lazy"
							/>
						</a>
					))}
				</div>
			) : (
				<p className="text-gray-700 text-sm">No images available</p>
			)}
		</Section>
	);
};
