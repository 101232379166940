import { Badge } from '@/app/atoms/badge';
import { BadgeProps, BadgeVariant } from '@/app/atoms/badge/types';
import { match } from '@/types/match';
import { useMemo } from 'react';
import type { CombinedOrderStatus } from '../../models';

type StatusInfo = {
	variant: BadgeVariant;
	text: string;
};

type CombinedOrderStatusBadgeProps = Omit<BadgeProps, 'variant' | 'children'> & {
	status: CombinedOrderStatus;
};

export const CombinedOrderStatusBadge = ({ status, ...rest }: CombinedOrderStatusBadgeProps) => {
	const info = useMemo<StatusInfo>(
		() =>
			match<CombinedOrderStatus, StatusInfo>(
				status,
				{
					Draft: () => ({ variant: 'neutral', text: 'Draft' }),
					Cancelled: () => ({ variant: 'red', text: 'Cancelled' }),
					NeedsAttention: () => ({ variant: 'yellow', text: 'Needs attention' }),
					Ordered: () => ({ variant: 'green', text: 'Ordered' }),
					Processing: () => ({ variant: 'blue', text: 'Processing' }),
					Confirmed: () => ({ variant: 'green', text: 'Confirmed' })
				},
				() => ({ variant: 'neutral', text: 'Unknown' })
			),
		[status]
	);

	return (
		<Badge variant={info.variant} {...rest}>
			{info.text}
		</Badge>
	);
};
