import { CamelCasedPropertiesDeep, SnakeCasedPropertiesDeep } from 'type-fest';

/**
 * Converts an object to camel case recursively
 *
 * @param item the item to convert
 */
export const recursiveToCamel = <T>(item: T): CamelCasedPropertiesDeep<T> =>
	recurse(item, (key: string) =>
		key.replace(/([_][a-z])/gi, c => c.toUpperCase().replace(/[-_]/g, ''))
	) as CamelCasedPropertiesDeep<T>;

/**
 * Converts an object to snake case recursively
 *
 * @param item the item to convert
 */
export const recursiveToSnake = <T>(item: T): SnakeCasedPropertiesDeep<T> =>
	recurse(item, (key: string) =>
		key.replace(/([A-Z])/g, '_$1').toLowerCase()
	) as SnakeCasedPropertiesDeep<T>;

/** Split string based on capitalisation */
export const splitToWords = (str: string): string => {
	let sanitisedStr = str;
	if (str.startsWith('Uvdb') || str.startsWith('Gapc')) {
		sanitisedStr = str.substring(4);
	}

	return sanitisedStr.split(/(?=[A-Z])/).join(' ');
};

/**
 * Recursively converts an object to specified transform
 *
 * @param item the item to convert
 * @param transform the function to transform the key
 */
const recurse = (item: unknown, transform: (key: string) => string): unknown => {
	if (Array.isArray(item)) {
		return item.map((el: unknown) => recurse(el, transform));
	}
	if (typeof item === 'function' || item !== Object(item)) {
		return item;
	}
	return Object.fromEntries(
		Object.entries(item as Record<string, unknown>).map(([key, value]: [string, unknown]) => [
			transform(key),
			recurse(value, transform)
		])
	);
};
