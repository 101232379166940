import { UvdbPropertyKey } from '@sdk/lib';
import { InputItem, VehicleSelectorFormData } from './types.ts';

export const createInputItems = (
	data: VehicleSelectorFormData,
	field: UvdbPropertyKey
): InputItem[] => {
	const properties = data[field] ?? [];

	return properties.map(({ id, name }) => ({
		value: id,
		label: name
	}));
};

export const getValueFromSuid = (
	key: UvdbPropertyKey,
	suid: string | null,
	data: VehicleSelectorFormData
) => {
	if (!suid) {
		return null;
	}

	const property = data[key]?.find(property => property.id === suid);

	return property ?? null;
};

export const splitToWords = (str: string): string => {
	const sanitisedStr = sanitisePropString(str);

	return sanitisedStr.split(/(?=[A-Z])/).join(' ');
};

export const sanitisePropString = (str: string): string => {
	let sanitisedStr = str;
	if (str.startsWith('Uvdb') || str.startsWith('Gapc')) {
		sanitisedStr = str.substring(4);
	}

	return sanitisedStr;
};
