import { Badge } from '@/app/atoms/badge';
import { BadgeProps, BadgeVariant } from '@/app/atoms/badge/types';
import { order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { useMemo } from 'react';
import { getReturnReason } from '../../utils';

type StatusInfo = {
	variant: BadgeVariant;
	text: string;
};

type ReturnDecisionBadgeProps = Omit<BadgeProps, 'variant' | 'children'> & {
	status: order.OrderItemStatus;
};

export const ReturnDecisionBadge = ({ status, ...rest }: ReturnDecisionBadgeProps) => {
	const info = useMemo<StatusInfo | null>(() => {
		if (typeof status === 'object' && 'returned' in status) {
			if (!status.returned.decision) {
				return { variant: 'neutral', text: 'Undecided' };
			}

			return match<order.OrderItemReturnDecision, StatusInfo>(
				status.returned.decision,
				{
					accepted: () => ({ variant: 'green', text: 'Accepted' }),
					rejected: ({ reason }) => ({
						variant: 'red',
						text: getReturnReason(reason) ?? 'Undecided'
					}),
					undecided: () => ({ variant: 'neutral', text: 'Undecided' })
				},
				() => ({ variant: 'neutral', text: 'Undecided' })
			);
		}

		return null;
	}, [status]);

	if (!info) {
		return null;
	}

	return (
		<Badge variant={info.variant} {...rest}>
			{info.text}
		</Badge>
	);
};
