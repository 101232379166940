import { DateCell, DefaultText, HeaderCell } from '@/app/common/components/molecules/cell-builder';
import { BasicTable } from '@/app/molecules/order-table';
import { formatPrice } from '@/app/utils/currency';
import { InheritableElementProps } from '@/types/utilties';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ActionIcon, Popover } from '@mantine/core';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { CombinedOrderItem } from '../../models';
import { CombinedOrderStatusBadge } from '../combined-order-status-badge';

const columnHelper = createColumnHelper<CombinedOrderItem>();
const columns = [
	columnHelper.accessor('name', {
		id: 'name',
		header: () => <HeaderCell>Vendor</HeaderCell>,
		cell: info => (
			<Link to={info.row.original.href}>
				<DefaultText className="hover:underline">{info.getValue()}</DefaultText>
			</Link>
		)
	}),
	columnHelper.accessor('statusInfo', {
		id: 'status',
		header: () => <HeaderCell>Status</HeaderCell>,
		cell: info => {
			const statusInfo = info.getValue();
			return (
				<div className="flex items-center gap-2">
					<CombinedOrderStatusBadge status={statusInfo.status} size="small" />
					{statusInfo.details && (
						<Popover width={200} position="bottom" withArrow shadow="md">
							<Popover.Target>
								<ActionIcon size="sm">
									<InformationCircleIcon />
								</ActionIcon>
							</Popover.Target>
							<Popover.Dropdown>
								<div className="text-sm text-gray-900">{statusInfo.details}</div>
							</Popover.Dropdown>
						</Popover>
					)}
				</div>
			);
		}
	}),
	columnHelper.accessor('deliveryInfo', {
		id: 'deliveryDate',
		header: () => <HeaderCell>Delivery</HeaderCell>,
		cell: info => {
			const deliveryInfo = info.getValue();
			return (
				<div className="flex items-center gap-2">
					<DateCell value={deliveryInfo.date} />
					{deliveryInfo.details && (
						<Popover width={200} position="bottom" withArrow shadow="md">
							<Popover.Target>
								<ActionIcon size="sm">
									<InformationCircleIcon />
								</ActionIcon>
							</Popover.Target>
							<Popover.Dropdown>
								<div className="text-sm text-gray-900">{deliveryInfo.details}</div>
							</Popover.Dropdown>
						</Popover>
					)}
				</div>
			);
		}
	}),
	columnHelper.accessor('totalPrice', {
		id: 'totalPrice',
		header: () => <HeaderCell className="text-right">Total</HeaderCell>,
		cell: info => (
			<DefaultText className="text-right">{formatPrice(info.getValue() ?? 0)}</DefaultText>
		)
	})
];

export const CombinedOrderSummaryTable = ({
	items,
	...rest
}: InheritableElementProps<'table', { items: CombinedOrderItem[] }>) => {
	const table = useReactTable({
		data: items,
		columns,
		getCoreRowModel: getCoreRowModel()
	});

	return <BasicTable table={table} {...rest} />;
};
