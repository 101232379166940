import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';

// todo: general cleanup, having to do some hacky stuff to get the scroll height

// height of summary bar: todo calculate dynamically
const MIN_SNAP = '76px';

export const useOrderRequestsDrawer = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [_scrollHeight, setScrollHeight] = useState(0);
	const [activeSnapPoint, setActiveSnapPoint] = useState<number | string | null>(MIN_SNAP);

	let bodyHeight = use100vh();
	if (!bodyHeight) {
		bodyHeight = window.innerHeight;
	}

	const maxSnap = `${bodyHeight * 0.95}px`;
	const midSnap = `${bodyHeight * 0.45}px`;
	const isOpen = activeSnapPoint !== MIN_SNAP;
	const isFullOpen = activeSnapPoint === maxSnap;

	const snapPoints = useMemo(() => [MIN_SNAP, midSnap, maxSnap], [midSnap]);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('order')) {
			openDrawer();
		}
	}, []);

	const closeDrawer = () => {
		setActiveSnapPoint(MIN_SNAP);
		setTimeout(() => refreshHeight(ref.current as HTMLElement));
	};

	const openDrawer = () => {
		setActiveSnapPoint(maxSnap);
		setTimeout(() => refreshHeight(ref.current as HTMLElement));
	};

	const refreshHeight = (target: HTMLElement) => {
		const regex = /translate3d\([^)]+,\s*(-?\d+(\.\d+)?)px,\s*[^)]+\)/;
		const transform = target.style?.transform ?? '';
		const match = transform.match(regex);

		if (match) {
			const middleNumber = match[1];
			setScrollHeight(Number(middleNumber) + 16);
		}
	};

	useMutationObserver(ref, data => {
		refreshHeight(data[0].target as HTMLElement);
	});

	const scrollHeight = bodyHeight - _scrollHeight;

	return {
		isOpen,
		isFullOpen,
		snapPoints,
		activeSnapPoint,
		closeDrawer,
		openDrawer,
		setActiveSnapPoint,
		scrollHeight,
		ref
	};
};

const useMutationObserver = (
	ref: RefObject<HTMLDivElement>,
	callback: MutationCallback,
	options = {
		attributes: true,
		characterData: true,
		childList: true,
		subtree: true
	}
) => {
	useEffect(() => {
		if (ref.current) {
			const observer = new MutationObserver(callback);
			observer.observe(ref.current, options);
			return () => observer.disconnect();
		}
	}, [callback, options]);
};
