import { OrderRequestsDrawer } from '@/app/features/supply/components/order-request-drawer';
import { PartSupplySelector } from '@/app/features/supply/components/part-supply-selector';
import { useBackgroundDraftOrderRefetch } from '@/app/features/supply/hooks/use-background-draft-order-refetch';
import { useSupplyRecommendations } from '@/app/features/supply/hooks/use-supply-recommendations';
import {
	DraftOrderSelection,
	JobPartItemSelection,
	OrderRequestModel
} from '@/app/features/supply/models';
import {
	applyOfferSelection,
	buildIngestFromSelection as buildEntitiesFromSelection,
	getDeliveryDateFromOrders,
	onRecommendationSelect
} from '@/app/features/supply/order-request';
import {
	anyOfferFulfillsMultipleJobParts,
	createInitialSelection
} from '@/app/features/supply/supply';
import { withSignedIn } from '@/app/hoc/with-access';
import { useUnsavedChanges } from '@/app/hooks/use-unsaved-changes';
import { tlsx } from '@/app/utils/tw-merge';
import { mutations } from '@/sdk/react/mutations';
import { bmsMutations } from '@/sdk/react/mutations/bms';
import { queries } from '@/sdk/react/queries';
import { api_error } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { jobsQueries } from '@sdk/react';
import { useMutation, useSuspenseQueries } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { JobSubActions } from '../job-detail';
import { RepairApp } from '@partly/analytics';
import { useAnalytics } from '@/app/hooks/use-analytics';

type PageParams = {
	jobId: string;
};

const onOrderIngestError = (e: api_error.RepairerServerError) => {
	match(
		e,
		{
			BadRequest: e => {
				toast.error(e);
			},
			InternalServerError: e => {
				toast.error(`An unexpected error occurred: ${e}`);
			},
			NotFound: e => {
				toast.error(`Resource not found: ${e}`);
			},
			Application: _ => {
				/** No-op */
			},
			Unauthorized: _ => {
				/** No-op */
			}
		},
		() => {
			toast.error('An unexpected error occurred');
		}
	);
};

const SupplyPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const analytics = useAnalytics();

	const {
		mutateAsync: syncJobParts,
		isSuccess,
		reset: resetBmsSyncState
	} = useMutation({
		...bmsMutations.syncJobParts,
		onSuccess: () => {
			toast.success('Parts are updated in iBodyShop');
		}
	});

	const navigate = useNavigate();
	const { mutateAsync: ingestOrders } = useMutation({
		...mutations.draft_orders.ingest,
		onError: onOrderIngestError
	});

	// We set gc time to 0 so that the queries are immedietly garbage collected
	// after component is removed. The data should only update is going back to
	// parts page or if a save occurs.
	const [{ data: jobData }, { data: recommendationData }, { data: drafOrderData }] =
		useSuspenseQueries({
			queries: [
				{ ...jobsQueries.get({ jobId }), refetchOnMount: false },
				{ ...jobsQueries.getSupplyRecommendations({ jobId }), gcTime: 0 },
				{ ...queries.draft_orders.list({ job_id: jobId }), gcTime: 0 }
			]
		});

	const form = useForm<DraftOrderSelection>({
		defaultValues: createInitialSelection(
			drafOrderData.draft_orders,
			recommendationData,
			jobData.job.repairerSiteId
		)
	});

	useBackgroundDraftOrderRefetch(jobId, form);

	const selection = form.watch();
	const { recommendations, jobParts, vendors } = useSupplyRecommendations(
		selection,
		recommendationData
	);

	const onOfferSelect = (prev: JobPartItemSelection | null, next: JobPartItemSelection) => {
		applyOfferSelection(prev, next, form, recommendationData);
		resetBmsSyncState();
	};

	// todo: object config
	const onRequestOrders = async (
		data: DraftOrderSelection,
		instantAccept: boolean,
		process: boolean
	) => {
		if (!recommendationData.supplyHashId) {
			// This should never happen, you can't make a selection
			// without having supply options.
			throw new Error('Supply hash id is missing');
		}

		const entities = buildEntitiesFromSelection(
			data,
			drafOrderData.draft_orders,
			recommendationData.supplyHashId,
			instantAccept
		);

		const result = await ingestOrders({
			repairer_site_id: data.repairer_site_id,
			job_id: jobId,
			entities,
			options: {
				/// Whether or not processed drafts
				/// should be finalised transitioned into real orders.
				finalise_processed: process,
				/// Whether or not draft orders should be transitioned
				/// into processing.
				process_drafts: process
			}
		});

		if (!process) {
			analytics.logEvent(
				RepairApp.supply_selection.supply_finalised({
					num_suppliers: result.draft_orders.length
					// don't have this data
					// num_new_parts: 0,
					// num_used_parts: 0
				})
			);
		}
		if (process) {
			result.draft_orders.forEach(order => {
				// This method is called with all orders even ones previously cancelled.
				if (typeof order.status === 'object' && 'Cancelled' in order.status) {
					return;
				}

				const ctx = {
					order_id: order.id,
					supplier_id: order.vendor.Partner.id,
					supplier_name: order.vendor.Partner.name,
					order_value: order.items.reduce((acc, item) => acc + (item.price ?? 0), 0),
					num_skus: order.items.length
				};

				// TODO bug - this will get called again on previously finalised orders
				if (order.status === 'Finalised') {
					analytics.logEvent(
						RepairApp.orders.order_finalised({
							...ctx,
							order_status: 'FINALISED'
						})
					);
				} else {
					analytics.logEvent(
						RepairApp.orders.order_sent({
							...ctx,
							order_status: 'PLACED'
						})
					);
				}
			});
		}

		const newState = createInitialSelection(
			result.draft_orders,
			recommendationData,
			data.repairer_site_id
		);

		form.reset(newState);
		resetBmsSyncState();
		toast.success(`Changes ${process ? 'sent' : 'saved'}`);

		if (process) {
			navigate(`/job/${jobId}`);
		}
	};

	const onCancel = async (request: OrderRequestModel) => {
		// Cancel is special case, we cancel immedietly and
		// we don't want to override any other potentially pending
		// changes.
		const result = await ingestOrders({
			repairer_site_id: jobData.job.repairerSiteId!,
			job_id: jobId,
			entities: [
				{
					Cancel: request.local_id
				}
			],
			options: {}
		});

		analytics.logEvent(
			RepairApp.orders.order_cancelled({
				order_id: request.local_id,
				supplier_id: request.vendor.Partner.id,
				supplier_name: request.vendor.Partner.name,
				order_status: 'CANCELLED'
			})
		);

		const newState = result.draft_orders.find(order => order.id === request.local_id);
		const existingState = form.getValues().draft_orders[request.local_id];
		if (!existingState || !newState) {
			return;
		}

		// These are the only fields that can change.
		existingState.status = newState.status;
		existingState.updated_at = newState.updated_at;
		form.setValue(`draft_orders.${request.local_id}`, existingState);
	};

	const onSyncBms = async () => {
		await syncJobParts({ jobId });
	};

	useUnsavedChanges(({ nextLocation }) => {
		const isConfirmation = nextLocation.pathname.startsWith(`/job/${jobId}/orders`);
		const isBlocking = form.formState.isDirty && !form.formState.isSubmitting && !isConfirmation;
		return isBlocking;
	});

	const onRecommendationSelectHandler = (offerIds: string[]) => {
		const newSelection = onRecommendationSelect(
			offerIds,
			recommendationData,
			form.getValues().draft_orders
		);

		const models = Object.values(newSelection);
		const deliveryDate = getDeliveryDateFromOrders(models, selection.delivery_date);
		form.setValue('draft_orders', newSelection);
		form.setValue('delivery_date', deliveryDate);
		resetBmsSyncState();
	};

	return (
		<>
			{jobData.job.bmsIntegrated && (
				<JobSubActions>
					<Button
						size="sm"
						variant="default"
						onClick={onSyncBms}
						disabled={!jobData.job.bmsIntegrated || isSuccess}
						leftIcon={<ArrowPathIcon className="w-4 h-4" />}
					>
						Sync to iBodyShop
					</Button>
				</JobSubActions>
			)}
			<div className="flex flex-col flex-1 gap-4 pb-24">
				<div className="flex flex-row items-center justify-between">
					<h1 className="text-xl font-medium">Supply options</h1>
					{vendors.length > 0 && !anyOfferFulfillsMultipleJobParts(recommendationData) && (
						<div className="flex items-center gap-2">
							<Button.Group className="[&>:not(:last-child)]:border-r-0">
								{recommendations.map((recommendation, index) => (
									<Button
										key={recommendation.id}
										size="sm"
										variant="default"
										// Yuck, but mantine styles get weird.
										className={tlsx({ '!border-r-0': index === 0 })}
										onClick={() => onRecommendationSelectHandler(recommendation.offerIds)}
									>
										{recommendation.taglines.join(', ')}
									</Button>
								))}
							</Button.Group>
						</div>
					)}
				</div>
				<PartSupplySelector
					className="p-2 mt-2 border rounded-md"
					jobParts={jobParts}
					suppliers={vendors}
					onOfferSelect={onOfferSelect}
				/>
				<OrderRequestsDrawer
					job={jobData.job}
					form={form}
					noSupplyParts={recommendationData.noSupply}
					onSave={data => onRequestOrders(data, false, false)}
					onRequestOrders={(data, instantAccept) => onRequestOrders(data, instantAccept, true)}
					onCancel={onCancel}
				/>
			</div>
		</>
	);
};

export default withSignedIn(SupplyPage);
