import {
	BmsSyncImagesRequest,
	BmsSyncImagesResponse,
	BmsSyncJobPartsRequest,
	BmsSyncJobPartsResponse,
	StorefrontError
} from '@/sdk/lib';
import { getApi, unwrap } from '../utils/helpers';
import { mutationOptions } from '../utils/mutation-options';

export const bmsMutations = {
	syncJobParts: mutationOptions<BmsSyncJobPartsResponse, StorefrontError, BmsSyncJobPartsRequest>({
		mutationKey: ['bms', 'parts', 'sync'],
		mutationFn: payload => unwrap(() => getApi().bms.syncJobParts(payload))
	}),
	syncJobImages: mutationOptions<BmsSyncImagesResponse, StorefrontError, BmsSyncImagesRequest>({
		mutationKey: ['bms', 'images', 'sync'],
		mutationFn: payload => unwrap(() => getApi().bms.syncImages(payload))
	})
};
