import { JobSiteSelection } from '@/app/features/jobs/components/job-site-selection';
import { withSignedIn } from '@/app/hoc/with-access';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Button } from '@mantine/core';
import { Job } from '@sdk/lib';
import { jobsQueries } from '@sdk/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { PageTemplate } from '@templates/page-template';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

type PageProps = {
	jobId: string;
};

const MAIN_ACTION_PORTAL_ID = 'job-main-action';
const SUB_ACTIONS_PORTAL_ID = 'job-sub-actions';

const JobDetailPage = () => {
	const { pathname } = useLocation();
	const { jobId } = useParams<PageProps>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	// const roomKey = createEsimatorJobPresenceRoomKey(jobId);
	// const presence = usePresence<EstimatorJobPresence>(roomKey, {
	//   job_id: jobId,
	//   user: {
	//     first_name: "Eli",
	//     surname: "Labes",
	//   },
	// });

	const {
		data: { job }
	} = useSuspenseQuery(jobsQueries.get({ jobId }));

	const path = useMemo(() => {
		if (pathname.endsWith('parts')) {
			return 'parts';
		}
		if (pathname.endsWith('supply')) {
			return 'supply';
		}
		if (pathname.endsWith('collision')) {
			return 'collision';
		}
		if (pathname.endsWith('orders')) {
			return 'order';
		}
		return 'job';
	}, [pathname]);

	const backTo = useMemo(() => {
		if (path === 'job') {
			return `/jobs?siteId=${job.repairerSiteId}`;
		}
		return `/job/${job.id}`;
	}, [job, path]);

	return (
		<PageTemplate className={tlsx({ 'p-0': path !== 'supply' })}>
			<PageTemplate.Header
				className={tlsx('grid items-center grid-cols-3 gap-4', {
					'border-b-0': pathname.endsWith('collision')
				})}
			>
				<div className="flex items-center gap-6 xl:gap-8">
					<Button
						component={Link}
						to={backTo}
						variant="default"
						leftIcon={<ArrowLeftIcon className="w-4 h-4" />}
					>
						{path === 'job' ? 'Home' : 'Back'}
					</Button>
					<JobSiteSelection job={job} />
				</div>
				<div className="flex justify-center min-w-max" id={MAIN_ACTION_PORTAL_ID} />
				<div className="flex items-center justify-end gap-6 xl:gap-8">
					{path !== 'job' && <VehicleDetails job={job} />}
					<div className="flex justify-end empty:hidden" id={SUB_ACTIONS_PORTAL_ID} />
				</div>
			</PageTemplate.Header>
			<Outlet />
		</PageTemplate>
	);
};

const VehicleDetails = ({
	className,
	job,
	...rest
}: InheritableElementProps<'a', { job: Job }>) => (
	<Link className={tlsx('group max-w-[30ch]', className)} to={`/job/${job.id}`} {...rest}>
		<dl>
			<dt className="sr-only">Vehicle name</dt>
			<dd className="text-sm font-medium leading-4 text-gray-900">
				{job.vehicle?.variant?.description}
			</dd>
			<dt className="sr-only">Plate / Chassis number</dt>
			<dd className="mt-0.5 text-xs leading-4 text-gray-400 truncate max-w-[25ch] group-hover:underline group-active:underline">
				{job.jobNumber} - {(job.vehicle?.plateNumber ?? job.vehicle?.chassisNumber)?.toUpperCase()}
			</dd>
		</dl>
	</Link>
);

export const JobMainAction = ({ children }: PropsWithChildren) => {
	const [isMounted, setIsMounted] = useState(false);
	// We need to wait for the component to be mounted
	// before rendering the portal, as in this case, the portal
	// id is a portal itself.
	useEffect(() => {
		setIsMounted(true);
	}, []);

	if (!isMounted) {
		return null;
	}

	const element = document.getElementById(MAIN_ACTION_PORTAL_ID);
	if (!element) {
		return null;
	}

	return createPortal(children, element);
};

export const JobSubActions = ({ children }: PropsWithChildren) => {
	const [isMounted, setIsMounted] = useState(false);
	// We need to wait for the component to be mounted
	// before rendering the portal, as in this case, the portal
	// id is a portal itself.
	useEffect(() => {
		setIsMounted(true);
	}, []);

	if (!isMounted) {
		return null;
	}

	const element = document.getElementById(SUB_ACTIONS_PORTAL_ID);
	if (!element) {
		return null;
	}

	return createPortal(children, element);
};

export default withSignedIn(JobDetailPage);
