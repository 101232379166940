import { Badge } from '@/app/atoms/badge';
import {
	CardRadioGroup,
	RadioLabel,
	RadioOption,
	RadioOptionProps,
	RadioOptions
} from '@/app/organisms/card-radio-group';
import { formatSubtotal } from '@/app/utils/currency';
import { formatShippingTime } from '@/app/utils/date';
import { formatSelectionContextReflect } from '@/app/utils/part';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { Button } from '@mantine/core';
import { ForwardedRef, forwardRef, Fragment, memo, useRef, useState } from 'react';
import { InputStepper } from '../../../../molecules/input-stepper';

import { formatGradeRange } from '@/app/utils/grade';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import {
	JobPartItemModel,
	JobPartItemSelection,
	OfferStatus,
	OrderPartSupplyOffer,
	type SupplyVendorOption
} from '../../models';
import { sortOffers } from '../../supply';

type JobPartItemProps = InheritableElementProps<
	'li',
	{
		item: JobPartItemModel;
		onChange?: (prev: JobPartItemSelection | null, next: JobPartItemSelection) => void;
	}
>;

const VISIBLE_OFFERS_LIMIT = 3;

export const JobPartItem = memo(({ item, className, onChange, ...rest }: JobPartItemProps) => {
	const [showAllOffers, setShowAllOffers] = useState(false);

	// Memoize recommendation so it can appear on top of the list
	const recommendedOfferId = useRef(item.selection?.offerId);
	const partName = formatSelectionContextReflect(item.context ? [item.context] : null);

	const onQuantityChange = (quantity: number) => {
		if (!item.selection) {
			return;
		}

		onChange?.(null, {
			...item.selection,
			quantity: quantity || 0
		});
	};

	const onSupplyOfferChange = (id: string) => {
		if (!item.selection) {
			onChange?.(null, { quantity: 1, offerId: id });
			return;
		}

		onChange?.(item.selection, { ...item.selection, offerId: id });
	};

	return (
		<li className={tlsx('flex gap-4', className)} {...rest}>
			<div className="flex-shrink-0 w-80">
				<div className="text-sm text-gray-900">{partName} </div>
				{!!item.context?.hcas?.length && (
					<div className="flex items-center flex-wrap gap-1 mt-0.5">
						{item.context.hcas.map((hca, index) => (
							<Fragment key={`${hca}-${index}`}>
								<span className="text-xs leading-none text-gray-600">{hca}</span>
								<ChevronRightIcon className="w-2 h-2 last:hidden" />
							</Fragment>
						))}
					</div>
				)}
				<div className="text-xs text-gray-700 mt-2.5">{item.context?.mpn}</div>
				<InputStepper
					className="mt-2 w-36"
					maxLength={3}
					disabled={!item.selection?.quantity}
					value={item.selection?.quantity ?? ''}
					onChange={onQuantityChange}
					min={0}
					max={999}
				/>
			</div>
			<CardRadioGroup className="flex-1" value="" onChange={onSupplyOfferChange}>
				<RadioLabel className="sr-only">{partName} suppliers</RadioLabel>
				<RadioOptions horizontal>
					{item.options.map(({ supplies, vendor }) => {
						const sortedOffers = sortOffers(supplies, recommendedOfferId.current);
						const remainingSuppliesCount = supplies.length - VISIBLE_OFFERS_LIMIT;
						return (
							<div className="flex flex-col gap-3" key={`{item.identity}_${vendor.id}`}>
								{supplies.length > 0 ? (
									(showAllOffers ? sortedOffers : sortedOffers.slice(0, VISIBLE_OFFERS_LIMIT)).map(
										option => {
											const { vendor, id } = option;

											return (
												<SupplyVendorOption
													key={`${vendor.id}-${id}`}
													checked={item.selection?.offerId === id}
													value={id}
													supplyOffer={option}
												/>
											);
										}
									)
								) : (
									<SupplyVendorOption value="" isUnavailable />
								)}
								{remainingSuppliesCount > 0 && !showAllOffers ? (
									<Button
										variant="white"
										className="text-blue-500 hover:bg-gray-50"
										onClick={() => setShowAllOffers(true)}
									>
										{remainingSuppliesCount === 1
											? 'View 1 more option'
											: `View ${remainingSuppliesCount} more options`}
									</Button>
								) : null}
							</div>
						);
					})}
				</RadioOptions>
			</CardRadioGroup>
		</li>
	);
});

type SupplyVendorOptionProps<T> = Omit<
	RadioOptionProps<T> & {
		checked?: boolean;
		supplyOffer?: OrderPartSupplyOffer;
		isUnavailable?: boolean;
	},
	'disabled' | 'aria-labelledby'
>;

const SupplyVendorOption = forwardRef(
	<T,>(
		{ supplyOffer, isUnavailable, checked, className, ...rest }: SupplyVendorOptionProps<T>,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		return (
			<RadioOption
				ref={ref}
				className={tlsx('flex flex-col p-0 border-none ring-0 space-y-2 w-56', className)}
				disabled={isUnavailable}
				aria-labelledby={`supplier-${supplyOffer?.vendor.id}`}
				{...rest}
			>
				{({ disabled }) => (
					<>
						<dl
							className={tlsx('p-2.5 ring-gray-300 ring-1 rounded-md space-y-0.5 flex-1', {
								'ring-2 ring-theme-600': checked,
								'bg-gray-50': disabled
							})}
						>
							{isUnavailable || !supplyOffer ? (
								<div className="h-16 text-sm text-gray-900">
									<dt className="sr-only">Availability</dt>
									<dd>No availability</dd>
								</div>
							) : (
								<>
									<div className="text-sm font-medium text-gray-900">
										<div className="flex items-center">
											<dt className="sr-only">Total</dt>
											<dd className="flex-1 leading-6">{formatSubtotal(supplyOffer.price)}</dd>
											<SupplyBadge status={supplyOffer.status} />
										</div>
									</div>
									{supplyOffer.offer.type === 'Kit' && (
										<div className="flex items-center gap-1 text-sm text-gray-600">
											<dt className="sr-only">Name</dt>
											<dd className="w-full break-words">{supplyOffer.offer.listing.name}</dd>
										</div>
									)}
									<div className="flex items-center gap-1 text-sm text-gray-600 ">
										<dt className="sr-only">Grade</dt>
										<dd>{supplyOffer.grade ? formatGradeRange([supplyOffer.grade]) : '--'}</dd>
									</div>
									<div className="text-sm text-gray-600">
										<dt className="sr-only">Delivery</dt>
										<dd>{formatShippingTime(supplyOffer.shipping)}</dd>
									</div>
								</>
							)}
						</dl>
					</>
				)}
			</RadioOption>
		);
	}
);

export const SupplyBadge = ({ status }: { status: OfferStatus | null }) => {
	if (!status) {
		return null;
	}

	switch (status) {
		case 'Ordered':
			return (
				<Badge size="small" variant="green">
					Ordered
				</Badge>
			);
		case 'Processing':
			return (
				<Badge size="small" variant="blue">
					Processing
				</Badge>
			);
		case 'Accepted':
			return (
				<Badge size="small" variant="green">
					Confirmed
				</Badge>
			);
		case 'Draft':
			return (
				<Badge size="small" variant="neutral">
					Draft
				</Badge>
			);
		case 'Rejected':
			return (
				<Badge size="small" variant="red">
					Rejected
				</Badge>
			);
	}
};
