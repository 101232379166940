import { order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';

export const getReturnReason = (reason: order.OrderItemReturnRejectedReason): string | null => {
	return match<order.OrderItemReturnRejectedReason, string | null>(
		reason,
		{
			damaged: () => 'Damaged',
			not_returnable: () => 'Not returnable',
			other: () => 'Other',
			out_of_return_policy: () => 'Out of return policy'
		},
		() => null
	);
};
