/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BmsSyncImagesRequest } from '../models/BmsSyncImagesRequest';
import type { BmsSyncPartsRequest } from '../models/BmsSyncPartsRequest';
import type { NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null } from '../models/NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BmsService {
	constructor(public readonly httpRequest: BaseHttpRequest) {}
	/**
	 * sync images for job
	 * @param requestBody
	 * @returns null
	 * @throws ApiError
	 */
	public bmsSyncImages(requestBody: BmsSyncImagesRequest): CancelablePromise<null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.bms.images.sync',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
	/**
	 * sync parts to bms for job
	 * @param requestBody
	 * @returns NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null
	 * @throws ApiError
	 */
	public bmsSyncParts(
		requestBody: BmsSyncPartsRequest
	): CancelablePromise<NormalizedResponse_for_SingularItemPayload_for_JobLink_and_Null> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api/jobs.bms.parts.sync',
			body: requestBody,
			mediaType: 'application/json'
		});
	}
}
