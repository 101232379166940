import { BusinessLogo } from '@/app/atoms/business-logo';
import { Site, useAuthenticatedUser } from '@/app/common/hooks/use-me';
import { tlsx } from '@/app/utils/tw-merge';
import { ALL_SITES_ID } from '@/config';
import { Job } from '@/sdk/lib';
import { InheritableElementProps } from '@/types/utilties';
import { ArrowRightOnRectangleIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Menu } from '@mantine/core';
import { Link } from 'react-router-dom';
import { removeOrg } from '../../utils/name';

type JobSiteSelection = InheritableElementProps<
	'div',
	| {
			job: Job;
	  }
	| {
			site?: Site | null;
			onSelectSite: (siteId: string) => void;
			onLogout: () => void;
	  }
>;

export const JobSiteSelection = (props: JobSiteSelection) => {
	const { sites } = useAuthenticatedUser();

	if ('job' in props) {
		const { job, className } = props;
		const site = sites.find(({ id }) => job.repairerSiteId === id);
		return (
			<Link
				className={tlsx('group flex items-center justify-start gap-2.5', className)}
				to={`/jobs?siteId=${job.repairerSiteId}`}
			>
				<BusinessLogo business="partly" className="h-7 w-10" />
				<span className="font-semibold">{site ? removeOrg(site.name) : 'Unknown site'}</span>
			</Link>
		);
	}

	const { site, onSelectSite, onLogout, className, ...rest } = props;

	return (
		<Menu shadow="md" width="14rem" position="bottom-start">
			<div className={tlsx('flex items-center gap-1', className)} {...rest}>
				<BusinessLogo business="partly" className="w-10 h-7" />
				<Menu.Target>
					<button className="px-2 py-2 flex items-center" data-testid="user-menu">
						<h1 className="mr-3 text-base font-semibold text-black">
							{site ? removeOrg(site.name) : 'Unknown site'}
						</h1>
						<ChevronDownIcon className="w-4 h-4 text-black" />
					</button>
				</Menu.Target>
			</div>
			<Menu.Dropdown>
				{sites.map(site => (
					<Menu.Item key={site.id} onClick={() => onSelectSite(site.id)}>
						{site.name}
					</Menu.Item>
				))}
				<Menu.Divider />
				<Menu.Item onClick={() => onSelectSite(ALL_SITES_ID)}>All Sites</Menu.Item>
				<Menu.Divider />
				<Menu.Item
					data-testid="user-logout"
					color="red"
					onClick={onLogout}
					icon={<ArrowRightOnRectangleIcon className="w-4 h-4" />}
				>
					Log out
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
};
