import { tlsx } from '@/app/utils/tw-merge';
import { InheritableProps, InheritableThreeProps } from '@/types/utilties';
import { Decal, Edges, Html, Line } from '@react-three/drei';
import { ReactNode, useEffect, useState } from 'react';
import * as THREE from 'three';
import { useImageTexture } from '../../hooks/use-image-texture';
import { Slice } from '../../types';
import { hexToRgb } from '../../utils/color';
import { polygonNexus, vec2to3, vec3 } from '../../utils/geometry';
import { PartsHotpot } from '../parts-hotspot';

type PartsDiagramFallbackProps = InheritableThreeProps<
	'mesh',
	{
		url: string;
		ratio: number;
		scale: number;
		filter?: 'dimmed' | 'background' | 'opaque';
	}
>;

export const PartsDiagramFallback = ({
	url,
	ratio,
	scale,
	filter,
	...rest
}: PartsDiagramFallbackProps) => {
	return (
		<mesh renderOrder={0} {...rest}>
			<planeGeometry args={[scale, scale * ratio]} />
			<meshBasicMaterial color={0xffffff} opacity={1} transparent toneMapped={false} />
			<Html
				wrapperClass="!z-0"
				position={[0, 0, 0]}
				transform
				occlude="blending"
				distanceFactor={10}
				className={tlsx('flex items-center justify-center pointer-events-none opacity-50', {
					'opacity-100': filter === 'opaque',
					'opacity-5': filter === 'dimmed'
				})}
				style={{
					width: `${scale * 40}px`
				}}
			>
				<img src={url} />
			</Html>
		</mesh>
	);
};

type PartsDiagramBackgroundProps = InheritableThreeProps<
	'mesh',
	{
		url: string;
		ratio: number;
		scale: number;
		filter?: 'dimmed' | 'background' | 'opaque';
		children?: ReactNode;
	}
>;

export const PartsDiagramBackground = ({
	url,
	ratio,
	scale,
	filter,
	children,
	...rest
}: PartsDiagramBackgroundProps) => {
	const texture = useImageTexture(url);
	return (
		<mesh renderOrder={0} {...rest}>
			<planeGeometry args={[scale, scale * ratio]} />
			<meshBasicMaterial
				map={texture}
				color={0xffffff}
				opacity={filter === 'opaque' ? 1 : filter == 'dimmed' ? 0.05 : 0.5}
				transparent
				toneMapped={false}
			/>
			{children}
		</mesh>
	);
};

type PartsDiagramCodeLayerProps = InheritableThreeProps<
	'mesh',
	{
		rect: Slice<THREE.Vector2, 4>;
		color?: `#${string}`;
		opacity?: number;
	}
>;

export const PartsDiagramCodeLayer = ({
	rect,
	color,
	opacity,
	...rest
}: PartsDiagramCodeLayerProps) => {
	const shape = new THREE.Shape(rect);
	return (
		<mesh renderOrder={2} {...rest}>
			<shapeGeometry args={[shape]} />
			<meshBasicMaterial
				color={color ?? '#ffffff'}
				opacity={opacity ?? 1}
				transparent
				toneMapped={false}
			/>
			<Edges
				lineWidth={10}
				color={color ?? '#ffffff'}
				opacity={1}
				transparent
				toneMapped={false}
				renderOrder={0}
			/>
		</mesh>
	);
};

type PartsDiagramHotspotProps = InheritableThreeProps<
	'mesh',
	{
		point: THREE.Vector2;
		code: string | null;
		checked?: boolean;
		highlighted?: boolean;
		searched?: boolean;
		className?: string;
		onClick: () => void;
	}
>;

export const PartsDiagramHotspot = ({
	className,
	point,
	code,
	checked,
	highlighted,
	searched,
	onClick,
	...rest
}: PartsDiagramHotspotProps) => {
	const shape = new THREE.Shape([point]);
	return (
		<mesh renderOrder={4} {...rest}>
			<shapeGeometry args={[shape]} />
			<Html
				as="div"
				wrapperClass={tlsx(
					'flex items-center justify-center !z-[5]',
					{
						'!z-[6]': highlighted
					},
					className
				)}
				className="bg-white/0 border-0"
				position={vec2to3(point)}
			>
				<PartsHotpot
					code={code}
					checked={checked}
					highlighted={highlighted}
					searched={searched}
					onClick={onClick}
				/>
			</Html>
			<meshBasicMaterial color="#ffffff" opacity={0} transparent toneMapped={false} />
		</mesh>
	);
};

type PartsDiagramSegmentProps = InheritableThreeProps<
	'mesh',
	{
		polygon: THREE.Vector2[];
		url: string;
		ratio: number;
		scale: number;
		highlighted?: boolean;
		checked?: boolean;
		searched?: boolean;
		position?: THREE.Vector3;
		decalOrder?: number;
	}
>;

export const PartsDiagramSegment = ({
	polygon,
	position,
	ratio,
	scale,
	url,
	highlighted,
	checked,
	searched,
	decalOrder,
	onClick,
	...rest
}: PartsDiagramSegmentProps) => {
	const [hovering, setHovering] = useState(false);

	const texture = useImageTexture(url);
	const shape = new THREE.Shape(polygon);

	// mesh is not an html with a css class
	useEffect(() => {
		document.body.style.cursor = hovering ? 'pointer' : 'auto';
	}, [hovering]);

	return (
		<mesh
			onClick={onClick}
			onPointerOver={() => setHovering(true)}
			onPointerOut={() => setHovering(false)}
			{...rest}
		>
			<shapeGeometry args={[shape]} />
			<meshBasicMaterial
				color={'#ffffff'}
				opacity={1}
				transparent
				toneMapped={false}
				depthWrite={false}
				depthTest={false}
			/>
			<Decal
				position={position ?? vec3(0, 0, 0)}
				rotation={[0, 0, 0]}
				scale={[scale, scale * ratio, 1]}
				renderOrder={decalOrder ?? 1}
			>
				<meshBasicMaterial
					color={'#ffffff'}
					opacity={1}
					transparent
					toneMapped={false}
					depthWrite={false}
					depthTest={false}
				/>
				<colorReplaceMaterial
					map={texture}
					attach="material"
					target={[0, 0, 0]}
					replacement={
						highlighted ? hexToRgb('#2563eb') : searched ? hexToRgb('#713f12') : hexToRgb('#312e81')
					}
					// replacement={highlighted ? hexToRgb('#2563eb') : hexToRgb('#64748b')}
					opacity={1}
					tolerance={1}
					transparent
					polygonOffset
					polygonOffsetFactor={-1}
					toneMapped={false}
					depthWrite={false}
					depthTest={false}
				/>
			</Decal>
			<meshBasicMaterial
				color={highlighted ? '#60a5fa' : searched ? '#fef9c3' : '#ede9fe'}
				opacity={highlighted ? 0.3 : 0.15}
				transparent
				toneMapped={false}
				depthWrite={false}
				depthTest={false}
			/>
			{(highlighted || checked) && (
				<Edges linewidth={highlighted ? 2.5 : 2} scale={1} threshold={1} color="#2563eb" />
			)}
		</mesh>
	);
};

type PartsDiagramNexusLineProps = Omit<
	InheritableProps<
		typeof Line,
		{
			from: THREE.Vector2[];
			to: THREE.Vector2[];
			highlighted?: boolean;
			checked?: boolean;
		}
	>,
	'points'
>;

export const PartsDiagramNexusLine = ({
	from,
	to,
	highlighted,
	checked,
	...rest
}: PartsDiagramNexusLineProps) => {
	const line = polygonNexus(from, to);
	return (
		<>
			<Line
				points={line}
				color={'#2563eb'}
				lineWidth={3}
				opacity={highlighted ? 1 : checked ? 0.6 : 0.025}
				transparent
				renderOrder={3}
				{...rest}
			/>
		</>
	);
};
