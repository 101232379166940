import { withSignedIn } from '@/app/hoc/with-access';

import { EmptyState } from '@/app/atoms/empty-state';
import { JobDetails } from '@/app/features/job/components/job-details';
import { JobImageGrid } from '@/app/features/job/components/job-image-grid';
import { OrderBuilder } from '@/app/features/orders/components/order-builder';
import { queries } from '@/sdk/react/queries';
import { jobsQueries } from '@sdk/react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { PageTemplate } from '@/app/templates/page-template';

type PageParams = {
	jobId: string;
};

const OrdersPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const [{ data: jobData }, { data: orders }] = useSuspenseQueries({
		queries: [
			jobsQueries.get({ jobId }),
			// Refetching every 30 seconds in case orders are updated.
			{ ...queries.orders.list({ job_id: jobId }), refetchInterval: 30_000 }
		]
	});

	return (
		<PageTemplate className="bg-gray-50">
			<div className="w-full mx-auto max-w-7xl">
				<div className="grid grid-cols-10 gap-6">
					<div className="col-span-7 space-y-6">
						{orders.orders.length > 0 ? (
							orders.orders.map(order => (
								<OrderBuilder key={order.id} jobId={jobId} model={order} />
							))
						) : (
							<EmptyState>
								<EmptyState.Title>No orders yet</EmptyState.Title>
								<EmptyState.Description>
									Once you have placed an order, it will appear here.
								</EmptyState.Description>
							</EmptyState>
						)}
					</div>
					<aside className="self-start col-span-3 space-y-6">
						<JobDetails job={jobData.job} />
						<JobImageGrid jobId={jobId} />
					</aside>
				</div>
			</div>
		</PageTemplate>
	);
};

export default withSignedIn(OrdersPage);
