import { localiseGrade } from '@/app/utils/grade';
import { tlsx } from '@/app/utils/tw-merge';
import { listing } from '@/sdk/reflect/reflect';
import { InheritableElementProps } from '@/types/utilties';
import { formatDate } from 'date-fns';

export const HeaderCell = ({ className, ...rest }: InheritableElementProps<'div', object>) => {
	return (
		<DefaultText
			className={tlsx('w-full whitespace-nowrap font-semibold text-gray-900', className)}
			{...rest}
		/>
	);
};

export const DateCell = ({
	value,
	orderSeparately
}: {
	value: number | null;
	orderSeparately?: boolean;
}) => {
	if (!value) {
		return <DefaultText>--</DefaultText>;
	}

	const date = new Date(value);
	const formatted = formatDate(date, 'dd/MM, hh:mm a');
	return (
		<DefaultText className="whitespace-nowrap">
			{formatted}
			{orderSeparately ? ' (Back order)' : ''}
		</DefaultText>
	);
};

export const GradeCell = ({ value }: { value: listing.ListingGrade | null }) => {
	if (!value) {
		return <DefaultText>--</DefaultText>;
	}

	const formatted = localiseGrade(value);
	return <DefaultText>{formatted}</DefaultText>;
};

export const DefaultText = ({
	children,
	className,
	...rest
}: InheritableElementProps<'div', object>) => {
	return (
		<div className={tlsx('text-sm leading-6 text-left text-gray-700', className)} {...rest}>
			{children}
		</div>
	);
};
