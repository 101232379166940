import { decodeGapcPartIdentityKey, JobPart, PartSlotIds } from '@/sdk/lib';
import { isNil } from 'lodash-es';
import { v4 } from 'uuid';
import { DiagramAssemblyResources, PartsFormData, PartsSelection } from '../types';

export const createInitialPartsFormData = (
	parts: JobPart[],
	resources: DiagramAssemblyResources
): PartsFormData => {
	const res = parts.map((part, order) => {
		const direct = part.assembly ? resources.assemblies.get(part.assembly.id) : null;
		const assembly =
			direct && direct.part.partIdentity === part.partIdentity
				? direct
				: resources.parts.get(part.partIdentity)?.at(0);

		if (!isNil(assembly)) {
			return createSelectionFromJobPart(
				assembly.id,
				part,
				assembly.description,
				assembly.id,
				assembly.partSlotIds,
				assembly.hcas,
				order
			);
		}
		return createSelectionFromJobPart(
			v4(),
			part,
			part.description ?? 'Unknown part',
			null,
			null,
			[],
			order
		);
	});

	return Object.fromEntries(res);
};

const createSelectionFromJobPart = (
	id: string,
	part: JobPart,
	description: string,
	assemblyId: string | null,
	partSlotIds: PartSlotIds | null,
	hcas: string[],
	order: number
): [string, PartsSelection] => {
	return [
		id,
		{
			...decodeGapcPartIdentityKey(part.partIdentity),
			quantity: part.quantity,
			description,
			partSlotIds,
			assemblyId,
			hcas,
			order
		}
	];
};
