import { draft_order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { memo } from 'react';
import { AddItemPayload, OrderRequestModel } from '../../models';
import { CancelledOrderRequest } from '../cancelled-order-request';
import { DraftOrderRequest } from '../draft-order-request';
import { ProcessedOrderRequest } from '../processed-order-request';
import { ProcessingOrderRequest } from '../processing-order-request';
import { InheritableElementProps } from '@/types/utilties';

type OrderRequestBuilderProps = InheritableElementProps<
	'section',
	{
		model: OrderRequestModel;
		onChange: (model: OrderRequestModel | undefined) => void;
		onCancel: (model: OrderRequestModel) => void;
		onAddItem: (payload: AddItemPayload) => void;
	}
>;

export const OrderRequestBuilder = memo(
	({ model, onChange, onCancel, onAddItem, ...rest }: OrderRequestBuilderProps) => {
		const internalOnChange = (model: OrderRequestModel) => {
			if (model.items.length === 0) {
				onChange(undefined);
				return;
			}

			onChange(model);
		};

		return match<draft_order.DraftOrderStatus, React.ReactNode>(
			model.status,
			{
				Draft: () => (
					<DraftOrderRequest
						model={model}
						onChange={internalOnChange}
						onAddItem={onAddItem}
						{...rest}
					/>
				),
				Processing: () => (
					<ProcessingOrderRequest model={model} onCancel={() => onCancel(model)} {...rest} />
				),
				Processed: () => (
					<ProcessedOrderRequest
						model={model}
						onChange={internalOnChange}
						onCancel={() => onCancel(model)}
						{...rest}
					/>
				),
				Cancelled: () => <CancelledOrderRequest model={model} {...rest} />,
				// We are experimenting with not showing finalised order request
				// <FinalisedOrderRequest model={model} />,
				Finalised: () => null
			},
			() => null
		);
	}
);
