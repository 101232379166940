import { Site } from '@/app/common/hooks/use-me';
import JobStatusBadge from '@/app/features/jobs/components/job-status-badge';
import { jobContextFromJob, useAnalytics } from '@/app/hooks/use-analytics';
import { tlsx } from '@/app/utils/tw-merge';
import { linkWithSearchParams } from '@/app/utils/url';
import { InheritableElementProps } from '@/types/utilties';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Menu } from '@mantine/core';
import { RepairApp } from '@partly/analytics';
import { Job, JobStatus } from '@sdk/lib';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { formatRelative } from 'date-fns';
import { enNZ } from 'date-fns/locale';
import { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { removeOrg } from '../../utils/name';

type JobListItemVehicle =
	| { kind: 'none' }
	| { kind: 'incomplete'; plate: string }
	| { kind: 'vehicle'; name: string; plate: string };

type JobListItemData = {
	arrival: string;
	vehicle: JobListItemVehicle;
	job: {
		id: string;
		label: string;
	};
	location: string;
	source: 'iBodyShop' | 'Partly';
	eta: string;
	status: JobStatus;
};

const relativeDate = (date: Date) => {
	const formatRelativeLocale = {
		yesterday: "'Yesterday at' hh:mm a",
		today: "'Today at' hh:mm a",
		tomorrow: "'Tomorrow at' hh:mm a",
		lastWeek: "dd/MM/yyyy 'at' hh:mm a",
		nextWeek: "dd/MM/yyyy 'at' hh:mm a",
		other: "dd/MM/yyyy 'at' hh:mm a"
	};

	return formatRelative(date, new Date(), {
		locale: {
			...enNZ,
			formatRelative: token => formatRelativeLocale[token]
		}
	});
};

const remToPixel = (rem: number) =>
	rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

const linkForJobState = (job: Job): string => {
	// missing all required information about vehicle (need either rego or vin)
	if (!job.vehicle || (!job.vehicle.plateNumber && !job.vehicle.chassisNumber)) {
		return linkWithSearchParams('/jobs/create', {
			siteId: job.repairerSiteId,
			jobId: job.id,
			jobNumber: job.jobNumber ?? undefined,
			claimNumber: job.claimNumber ?? undefined
		});
	}

	// missing variant (but there's either rego or vin)
	if (!job.vehicle.variant) {
		return linkWithSearchParams('/jobs/create/vehicles', {
			jobId: job.id,
			jobNumber: job.jobNumber ?? undefined,
			claimNumber: job.claimNumber ?? undefined,
			chassisNumber: job.vehicle.chassisNumber ?? undefined,
			plateNumber: job.vehicle.plateNumber ?? undefined,
			plateState: job.vehicle.plateState ?? undefined
		});
	}

	return `/job/${job.id}`;
};

const columnHelper = createColumnHelper<JobListItemData>();

type VirtualizedJobTableProps = {
	sites: Site[];
	jobs: Job[];
	hasNextPage: boolean;
	isNextPageLoading: boolean;
	loadNextPage: () => void;
	onClose: (job: Job) => void;
	onDelete: (job: Job) => void;
	size: Size;
};

const VirtualizedJobTable = ({
	sites,
	jobs,
	hasNextPage,
	isNextPageLoading,
	loadNextPage,
	onClose,
	onDelete,
	size
}: VirtualizedJobTableProps) => {
	// compute the size of each job list item
	// the size of each list item is a fixed size but in rem not in pixels
	const itemSize = remToPixel(6);

	// We need a reference to the scrolling element for logic down below
	const tableContainerRef = useRef<HTMLDivElement>(null);

	const [scrollOffset, setScrollOffset] = useState(0);
	const deferredOffset = useDeferredValue(scrollOffset);
	const { logEvent } = useAnalytics();

	const data = useMemo(
		() =>
			jobs.map((job): JobListItemData => {
				const vehicle: JobListItemVehicle = job.vehicle
					? job.vehicle.variant
						? {
								kind: 'vehicle',
								name: job.vehicle.variant.description,
								plate: job.vehicle.plateNumber ?? job.vehicle.chassisNumber ?? '-'
							}
						: {
								kind: 'incomplete',
								plate: job.vehicle.plateNumber ?? job.vehicle.chassisNumber ?? '-'
							}
					: { kind: 'none' };
				return {
					arrival: relativeDate(new Date(job.createdAt)),
					vehicle: vehicle,
					job: {
						id: job.id,
						label: job.jobNumber ?? '-'
					},
					status: job.status,
					location: removeOrg(sites.find(({ id }) => job.repairerSiteId === id)?.name ?? '-'),
					source: job.bmsIntegrated ? 'iBodyShop' : 'Partly',
					eta: job.deliveryBeforeAt
						? new Date(job.deliveryBeforeAt).toLocaleDateString('en-NZ')
						: '-'
				};
			}),
		[jobs, sites]
	);

	const onNavigateAnalytics = (job: Job) => {
		const context = jobContextFromJob(job);
		logEvent(
			RepairApp.jobs.job_opened({
				// TODO:- we should standardise status in UI and data
				job_status: job.status,
				...context
			})
		);
	};

	const columns = useMemo(
		() => [
			columnHelper.accessor('arrival', {
				id: 'arrival',
				cell: info => {
					const arrival = info.getValue();
					return (
						<div className="flex items-center h-full text-gray-600 px-2 [text-wrap:balance]">
							{arrival}
						</div>
					);
				},
				// todo (vincent) change back to arrival when we have proper arrival
				header: () => <span className="text-sm font-semibold text-gray-600">Created at</span>,
				enableMultiSort: true,
				size: (size.width * 2) / 16
			}),
			columnHelper.accessor('vehicle', {
				id: 'vehicle',
				cell: info => {
					const vehicle = info.getValue();
					const job = jobs[info.row.index];
					const link = linkForJobState(job);

					switch (vehicle.kind) {
						case 'none':
							return (
								<Link
									className="text-base font-semibold text-gray-600"
									to={link}
									data-testid={`job-row-vehicle-${job.id}`}
									onClick={() => onNavigateAnalytics(job)}
								>
									No Vehicle selected
								</Link>
							);
						case 'incomplete':
							return (
								<Link
									to={link}
									data-testid={`job-row-vehicle-${job.id}`}
									onClick={() => onNavigateAnalytics(job)}
								>
									<div className="max-w-full text-base font-semibold text-gray-600 truncate">
										Incomplete vehicle selection
									</div>
								</Link>
							);
						case 'vehicle':
							return (
								<Link
									to={link}
									data-testid={`job-row-vehicle-${job.id}`}
									onClick={() => onNavigateAnalytics(job)}
								>
									<div className="max-w-full text-base font-semibold text-gray-600 truncate">
										{vehicle.name}
									</div>
									<div className="max-w-full text-sm text-gray-500 truncate">{vehicle.plate}</div>
								</Link>
							);
					}
				},
				header: () => <span className="text-sm font-semibold text-gray-600">Vehicle</span>,
				minSize: (size.width * 2.5) / 16
			}),
			columnHelper.accessor('job', {
				id: 'job',
				cell: info => {
					const { label, id } = info.getValue();
					const job = jobs[info.row.index];
					const link = linkForJobState(job);

					return (
						<Link
							className="flex items-center gap-2.5"
							to={link}
							data-testid={`job-row-date-${id}`}
							onClick={() => onNavigateAnalytics(job)}
						>
							<span className="text-gray-600 truncate">{label}</span>
						</Link>
					);
				},
				header: () => <span className="text-sm font-semibold text-gray-600">Job</span>,
				size: size.width / 12
			}),
			columnHelper.accessor('location', {
				id: 'location',
				cell: info => {
					const location = info.getValue();
					return <div className="flex items-center h-full text-gray-600">{location}</div>;
				},
				header: () => <span className="text-sm font-semibold text-gray-600">Location</span>,
				size: (size.width * 2) / 16
			}),
			columnHelper.accessor('source', {
				id: 'source',
				cell: info => {
					const source = info.getValue();
					return <div className="flex items-center h-full text-gray-600">{source}</div>;
				},
				header: () => <span className="text-sm font-semibold text-gray-600">Source</span>,
				size: size.width / 16
			}),
			columnHelper.accessor('eta', {
				id: 'eta',
				cell: info => {
					const eta = info.getValue();
					return <div className="flex items-center h-full text-gray-600">{eta}</div>;
				},
				header: () => <span className="text-sm font-semibold text-gray-600">Parts ETA</span>,
				size: (size.width * 1.5) / 16
			}),
			columnHelper.accessor('status', {
				id: 'status',
				cell: info => {
					const status = info.getValue();
					const job = jobs[info.row.index];
					return (
						<Link
							to={`/job/${job.id}/orders`}
							className="flex items-center w-full h-full"
							data-testid={`job-row-status-${job.id}`}
							onClick={() => onNavigateAnalytics(job)}
						>
							<JobStatusBadge status={status} />
						</Link>
					);
				},
				header: () => <span className="text-sm font-semibold text-gray-600">Status</span>,
				size: size.width / 16
			}),
			columnHelper.display({
				id: 'actions',
				cell: ({ row }) => {
					const job = jobs[row.index];
					return (
						<div className="flex items-center justify-end h-full text-gray-600">
							<Menu shadow="lg" width="12rem" position="left" withinPortal>
								<Menu.Target>
									<ActionIcon>
										<EllipsisHorizontalIcon
											className="w-5 h-5"
											data-testid={`job-delete-menu-${job.id}`}
										/>
									</ActionIcon>
								</Menu.Target>

								<Menu.Dropdown className="z-50">
									<Menu.Item
										icon={<XMarkIcon className="w-4 h-4" />}
										disabled={job.status === 'Closed'}
										onClick={() => onClose(job)}
										data-testid={`job-close-${job.id}`}
									>
										Close job
									</Menu.Item>
									<Menu.Item
										color="red"
										icon={<TrashIcon className="w-4 h-4" />}
										onClick={() => onDelete(job)}
										data-testid={`job-delete-${job.id}`}
									>
										Delete job
									</Menu.Item>
								</Menu.Dropdown>
							</Menu>
						</div>
					);
				},
				maxSize: size.width / 16
			})
		],
		[size]
	);

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel()
	});

	const { rows } = table.getRowModel();
	const headerGroups = table.getHeaderGroups();

	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		estimateSize: () => itemSize,
		getScrollElement: () => tableContainerRef.current,
		measureElement:
			typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
				? element => element?.getBoundingClientRect().height
				: undefined,
		overscan: 5
	});

	const virtualRows = rowVirtualizer.getVirtualItems();

	const loadOnEndReached = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				// load when reaching within 5 rows to the bottom
				const threshold = itemSize * 5;
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
				const distanceToBottom = scrollHeight - scrollTop - clientHeight;
				if (distanceToBottom < threshold && !isNextPageLoading && hasNextPage) {
					loadNextPage();
				}
			}
		},
		[loadNextPage, isNextPageLoading, hasNextPage, itemSize]
	);

	const onContainerScroll = useCallback(
		(event: React.UIEvent<HTMLDivElement, UIEvent>) => {
			if (event.target instanceof HTMLDivElement) {
				loadOnEndReached(event.target);
				setScrollOffset(event.target.scrollTop);
			}
		},
		[loadOnEndReached]
	);

	useEffect(() => {
		loadOnEndReached(tableContainerRef.current);
	}, [loadOnEndReached]);

	return (
		<div
			className={tlsx('relative overflow-auto')}
			ref={tableContainerRef}
			onScroll={onContainerScroll}
			style={{
				...size
			}}
		>
			<table className="relative grid w-full h-auto">
				<thead
					className={tlsx('grid sticky top-0 z-10 w-full bg-white', {
						'border-b border-gray-200 shadow-lg shadow-black/5': deferredOffset > 0
					})}
				>
					{headerGroups.map(({ id, headers }) => (
						<tr key={id} className="flex justify-around w-full px-6 py-3 bg-white border-b">
							{headers.map(header => (
								<th
									key={header.id}
									className="flex items-center"
									style={{ width: header.column.getSize() }}
								>
									{flexRender(header.column.columnDef.header, header.getContext())}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody className="relative grid w-full" style={{ height: rowVirtualizer.getTotalSize() }}>
					{virtualRows.map(virtualRow => {
						const row = rows[virtualRow.index];
						const visibleCells = row.getVisibleCells();
						return (
							<tr
								key={row.id}
								className="absolute flex justify-around w-full max-w-full px-6 py-6 border-b border-gray-200"
								ref={node => rowVirtualizer.measureElement(node)}
								data-testid={`job-row-${row.original.job.id}`}
								data-index={virtualRow.index}
								data-job-row-id={row.original.job.id}
								style={{ transform: `translateY(${virtualRow.start}px)` }}
							>
								{visibleCells.map(cell => (
									<td
										className="flex flex-col justify-center z-[5]"
										key={cell.id}
										style={{
											width: cell.column.getSize()
										}}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

type JobTableProps = InheritableElementProps<'div', Omit<VirtualizedJobTableProps, 'size'>>;

const JobTable = ({
	sites,
	jobs,
	hasNextPage,
	isNextPageLoading,
	loadNextPage,
	onClose,
	onDelete,
	className,
	...rest
}: JobTableProps) => {
	return (
		<div className={tlsx('flex-1 pb-safe-offset-1', className)} {...rest}>
			<AutoSizer>
				{size => (
					<VirtualizedJobTable
						sites={sites}
						jobs={jobs}
						hasNextPage={hasNextPage}
						isNextPageLoading={isNextPageLoading}
						loadNextPage={loadNextPage}
						onClose={onClose}
						onDelete={onDelete}
						size={size}
					/>
				)}
			</AutoSizer>
		</div>
	);
};

export default JobTable;
