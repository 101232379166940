import { JobPartRaw } from '@/sdk/generated';
import { JobPart } from '../types';
import { Context } from '../utils/context';
import { encodeGapcPartIdentityKey } from '../utils/resources';
import { createGapcBrand, createPartSlot } from './gapc';

export const createJobPart = (jobPart: JobPartRaw, ctx: Context): JobPart | null => {
	const id = encodeGapcPartIdentityKey({
		gapcBrandId: jobPart.gapc_brand_id,
		mpn: jobPart.mpn
	});

	const partSlot = jobPart.part_slot ? createPartSlot(jobPart.part_slot, ctx) : null;
	const gapcBrand = jobPart.gapc_brand_id ? createGapcBrand(jobPart.gapc_brand_id, ctx) : null;

	return {
		quantity: jobPart.quantity,
		description: jobPart.description ?? null,
		mpn: jobPart.mpn,
		gapcBrand,
		partIdentity: id,
		assembly: jobPart.assembly,
		partSlot
	};
};

export const createJobParts = (jobParts: JobPartRaw[], ctx: Context): JobPart[] => {
	return jobParts
		.map(jobPart => createJobPart(jobPart, ctx))
		.filter((item): item is JobPart => !!item);
};
