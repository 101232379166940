import StandardDialog from '@/app/organisms/standard-dialog';
import { Job } from '@/sdk/lib';
import { InheritableElementProps } from '@/types/utilties';
import {
	Listbox,
	ListboxButton,
	ListboxOption,
	ListboxOptions,
	Transition
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Textarea } from '@mantine/core';
import { Fragment, useEffect, useId } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCloseJob } from '../../hooks/use-close-job';

const EXTERNAL_REASONS = [
	'Parts repaired',
	'No parts required',
	'Customer cancelled',
	'Out of scope',
	'Total Loss',
	'Other'
] as const;

const PARTLY_REASONS = ['Unable to find parts in Partly'] as const;

const schema = yup.object({
	reason: yup
		.string()
		.oneOf([...EXTERNAL_REASONS, ...PARTLY_REASONS])
		.required()
		.label('Reason'),
	note: yup
		.string()
		.when('reason', {
			is: (reason: string) => reason === 'Other',
			then: schema => schema.required(),
			otherwise: schema => schema.optional()
		})
		.label('Note')
});

export type JobCloseData = yup.InferType<typeof schema>;

type JobCloseDialog = InheritableElementProps<
	'div',
	{
		siteId: string;
		job?: Job | null;
		onClose: () => void;
	}
>;

export const JobCloseDialog = ({ siteId, job, onClose }: JobCloseDialog) => {
	const selectId = useId();
	const { control, handleSubmit, reset, watch } = useForm<JobCloseData>({
		defaultValues: {
			reason: 'Out of scope',
			note: ''
		},
		resolver: yupResolver(schema)
	});

	const reason = watch('reason');

	const closeJob = useCloseJob({
		siteId
	});

	const onSubmit = async (reason: JobCloseData) => {
		if (!job) {
			return;
		}

		await closeJob({ jobId: job.id, reason });
		reset();
		onClose();
	};

	useEffect(() => {
		reset();
	}, [job?.id]);

	return (
		<StandardDialog open={!!job} onClose={onClose}>
			<StandardDialog.Title>Close job #{job?.jobNumber}</StandardDialog.Title>
			<StandardDialog.Content className="w-full min-w-[32rem] max-w-2xl">
				<form className="flex flex-col gap-3 w-full" noValidate onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name="reason"
						render={({ field: { value, ...field } }) => (
							<Listbox value={value ?? 'Out of scope'} onChange={value => field.onChange(value)}>
								<div className="w-full">
									<label
										className="text-sm font-medium text-gray-900"
										aria-required
										htmlFor={selectId}
									>
										Reason{' '}
										<span className="text-red-600" aria-hidden>
											*
										</span>
									</label>
									<ListboxButton
										id={selectId}
										type="button"
										className="w-full ring-1 ring-gray-300 rounded-md flex items-center py-2 px-3 data-[open]:outline data-[open]:outline-2 data-[open]:outline-blue-500"
									>
										<span className="text-gray-800 text-sm">{value}</span>
										<ChevronDownIcon className="w-3.5 h-3.5 ml-auto" />
									</ListboxButton>
								</div>
								<Transition
									as={Fragment}
									enter="transition ease-in duration-100"
									enterFrom="opacity-0 -translate-y-2"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 -translate-y-1"
								>
									<ListboxOptions
										anchor="bottom start"
										className="absolute mt-1.5 max-h-60 w-[29rem] overflow-auto rounded-md z-50 bg-white px-1.5 py-2 text-base border border-gray-300 focus:outline-none"
									>
										{EXTERNAL_REASONS.map(reason => (
											<ListboxOption
												key={reason}
												value={reason}
												className="px-3 py-2 rounded data-[focus]:bg-gray-100 text-sm text-gray-900"
											>
												{reason}
											</ListboxOption>
										))}
										<div className="w-[calc(100%-1rem)] mx-2 bg-gray-300 h-px my-2" />
										{PARTLY_REASONS.map(reason => (
											<ListboxOption
												key={reason}
												value={reason}
												className="px-3 py-2 rounded data-[focus]:bg-gray-100 text-sm text-gray-900"
											>
												{reason}
											</ListboxOption>
										))}
									</ListboxOptions>
								</Transition>
							</Listbox>
						)}
					/>
					<Controller
						control={control}
						name="note"
						rules={{ required: reason === 'Other' }}
						render={({ field: { value, ...field } }) => (
							<Textarea
								label="Note"
								placeholder="Type any notes about closing this job here"
								className="w-full"
								required={reason === 'Other'}
								value={value ?? ''}
								{...field}
							/>
						)}
					/>
					<div className="flex justify-end items-center gap-2 w-full mt-2">
						<Button variant="white" color="dark" type="button" onClick={onClose}>
							Cancel
						</Button>
						<Button type="submit">Close job</Button>
					</div>
				</form>
			</StandardDialog.Content>
		</StandardDialog>
	);
};
