import { BasicTable } from '@/app/molecules/order-table';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Menu, Progress } from '@mantine/core';
import { createVendorOrderProcessingRoomKey, DriftDBProvider } from '@partly/driftdb-client';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { memo } from 'react';
import { getEnvironment } from '@/environment';
import { useOrderRequestTableItems } from '../../hooks/use-order-request-table-items';
import { useRealtimeProgress } from '../../hooks/use-realtime-progress';
import { OrderRequestModel } from '../../models';
import { draftOrderColumnBuilder } from '../../table-builder';
import { OrderRequestSection } from '../order-request-section';
import { ChatBubble } from '../chat-bubble';

type ProcessingOrderReuqestProps = InheritableElementProps<
	'section',
	{
		model: OrderRequestModel;
		onCancel: () => void;
	}
>;

const builder = draftOrderColumnBuilder();
const tableColumns = [
	builder.quantity(),
	builder.name(),
	builder.status(),
	builder.grade(),
	builder.arrivalAt(),
	builder.price()
];

export const ProcessingOrderRequest = memo(
	({ model, onCancel, ...rest }: ProcessingOrderReuqestProps) => {
		const { items, subtotal } = useOrderRequestTableItems(model.items);

		const table = useReactTable({
			data: items,
			columns: tableColumns,
			getCoreRowModel: getCoreRowModel()
		});

		const api = getEnvironment().driftdbUrl;
		const roomKey = createVendorOrderProcessingRoomKey(model.vendor.Partner.id);

		return (
			<OrderRequestSection {...rest}>
				<DriftDBProvider api={api} room={roomKey}>
					<OrderRequestSection.Content className="border-b">
						<RealtimeProcessing model={model} onCancel={onCancel} />
					</OrderRequestSection.Content>
					<OrderRequestSection.Content>
						<OrderRequestSection.Title>
							{model.vendor.Partner.name} (#{model.order_id})
						</OrderRequestSection.Title>
						<BasicTable className="mt-3" table={table} />
						<BasicTable.Subtotal subtotal={subtotal} />
						{model.estimator_notes && (
							<div className="mt-3">
								<ChatBubble
									label="Estimator"
									notes={model.estimator_notes}
									className="w-full max-w-lg"
								/>
							</div>
						)}
					</OrderRequestSection.Content>
				</DriftDBProvider>
			</OrderRequestSection>
		);
	}
);

type RealtimeProcessingProps = InheritableElementProps<
	'div',
	{
		model: OrderRequestModel;
		onCancel: () => void;
	}
>;

const RealtimeProcessing = ({ model, className, onCancel, ...rest }: RealtimeProcessingProps) => {
	const { message, stagnant, percentage } = useRealtimeProgress(model);
	return (
		<>
			<div className={tlsx('flex items-center justify-between', className)} {...rest}>
				<div>
					<div className="font-semibold text-gray-900">Order processing</div>
					{model.instant_accept && (
						<div className="text-sm text-gray-900">
							After supplier confirmation the order will be sent
						</div>
					)}
				</div>
				<div className="flex items-center gap-2">
					<span className="text-sm text-gray-700">{message}</span>
					<Menu position="bottom-end">
						<Menu.Target>
							<ActionIcon aria-label="Show options" size="md">
								<EllipsisVerticalIcon className="text-gray-700" />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item color="red" onClick={onCancel}>
								Cancel
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</div>
			</div>
			<Progress
				className="mt-3"
				color={model.instant_accept ? 'green' : 'blue'}
				value={percentage}
				// give some visial feedback if the progress bar
				// is not moving
				animate={stagnant}
			/>
		</>
	);
};
