import { CloseJobRequest, CloseJobResult } from '@sdk/lib';
import { jobsMutations, jobsQueries } from '@sdk/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UseCloseJobArgs = {
	siteId: string;
	onSettled?: (
		data: CloseJobResult | undefined,
		error: Error | null,
		variables: CloseJobRequest
	) => unknown;
};

export const useCloseJob = ({ siteId, onSettled }: UseCloseJobArgs) => {
	const queryClient = useQueryClient();

	const { mutateAsync: closeJob } = useMutation({
		...jobsMutations.close,
		onSuccess: (_data, variables) => {
			queryClient.invalidateQueries({
				predicate: query => {
					const searchJobQueryKeys = jobsQueries.search({ repairerSiteIds: [siteId] }).queryKey;

					// ignore the last key / payload
					return searchJobQueryKeys
						.slice(0, searchJobQueryKeys.length - 1)
						.every((key, index) => query.queryKey[index] === key);
				}
			});

			queryClient.invalidateQueries({
				queryKey: jobsQueries.get({ jobId: variables.jobId }).queryKey
			});
		},
		onSettled
	});

	return closeJob;
};
