import { BasicTable } from '@/app/molecules/order-table';
import StandardDialog from '@/app/organisms/standard-dialog';
import { isDefined } from '@/sdk/lib/utils/object';
import { draft_order } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { CheckCircleIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Button, Menu, Textarea } from '@mantine/core';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { memo, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOrderRequestTableItems } from '../../hooks/use-order-request-table-items';
import { DraftOrderItemModel, OrderRequestModel } from '../../models';
import { draftOrderColumnBuilder } from '../../table-builder';
import { OrderRequestSection } from '../order-request-section';
import { ChatBubble } from '../chat-bubble';
import { InheritableElementProps } from '@/types/utilties';

type ProcessedOrderRequestProps = InheritableElementProps<
	'section',
	{
		model: OrderRequestModel;
		onChange: (model: OrderRequestModel) => void;
		onCancel: () => void;
	}
>;

const builder = draftOrderColumnBuilder();
const tableColumns = [
	builder.select(),
	builder.quantity(),
	builder.name(),
	builder.status(),
	builder.grade(),
	builder.arrivalAt('Delivery date'),
	builder.price()
];

export const ProcessedOrderRequest = memo(
	({ model, onCancel, onChange, ...rest }: ProcessedOrderRequestProps) => {
		const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});
		const [targetIndex, setTargetIndex] = useState<number | null>(null);
		const { items, subtotal } = useOrderRequestTableItems(model.items);
		const { canRemove, orderSeperately, orderTogether } = useAllowedActions(
			rowSelection,
			model.items
		);

		const table = useReactTable({
			data: items,
			columns: tableColumns,
			state: {
				rowSelection
			},
			enableRowSelection: true,
			onRowSelectionChange: setRowSelection,
			getCoreRowModel: getCoreRowModel()
		});

		// const onConfirmOverride = () => {
		//   const selectedKey = Number(Object.keys(rowSelection)[0]);
		//   setTargetIndex(selectedKey);
		// };

		const onOrderSeperately = (separate: boolean) => {
			const selectedKeys = Object.keys(rowSelection).map(key => Number(key));
			onChange({
				...model,
				items: model.items.map((value, index) =>
					selectedKeys.includes(index)
						? {
								...value,
								order_separately: separate
							}
						: value
				)
			});

			setRowSelection({});
		};

		const onOverride = (reason: string) => {
			if (targetIndex === null) {
				return;
			}

			onChange({
				...model,
				items: model.items.map((value, index) =>
					index === targetIndex
						? {
								...value,
								status: {
									Approved: {
										reason: 'Estimator',
										details: reason
									}
								}
							}
						: value
				)
			});

			setTargetIndex(null);
			setRowSelection({});
		};

		const onNotWanted = () => {
			const selectedKeys = Object.keys(rowSelection).map(key => Number(key));
			onChange({
				...model,
				items: model.items.map((value, index) =>
					selectedKeys.includes(index)
						? {
								...value,
								order_separately: false,
								status: {
									Rejected: {
										reason: {
											Estimator: 'NotWanted'
										},
										details: null
									}
								}
							}
						: value
				)
			});

			setRowSelection({});
		};

		return (
			<OrderRequestSection {...rest}>
				<OrderRequestSection.Content className="flex items-center justify-between border-b">
					<div className="flex items-center gap-2">
						<CheckCircleIcon className="w-5 h-5 text-blue-500" />
						<span className="font-semibold text-gray-900">Order processed</span>
					</div>
					<div className="flex items-center gap-2">
						<span className="text-sm text-gray-700">
							Processed at {formatDate(model.updated_at ?? model.created_at, 'dd/MM, hh:mm a')}
						</span>
						<Menu position="bottom-end">
							<Menu.Target>
								<ActionIcon aria-label="Show options" size="md">
									<EllipsisVerticalIcon className="text-gray-700" />
								</ActionIcon>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item color="red" onClick={onCancel}>
									Cancel
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</div>
				</OrderRequestSection.Content>
				<OrderRequestSection.Content>
					<div className="flex items-center justify-between">
						<OrderRequestSection.Title>
							{model.vendor.Partner.name} (#{model.order_id})
						</OrderRequestSection.Title>
						<div className="flex items-center justify-between">
							<div className="flex items-center gap-2">
								{canRemove && (
									<Button variant="default" size="xs" onClick={onNotWanted}>
										Not wanted
									</Button>
								)}
								{/* todo future */}
								{/* {overrideReject && (
                  <Button variant="default" size="xs" onClick={onConfirmOverride}>
                    Override rejection
                  </Button>
                )} */}
								{orderSeperately && (
									<Button variant="default" size="xs" onClick={() => onOrderSeperately(true)}>
										Order separately
									</Button>
								)}
								{orderTogether && (
									<Button variant="default" size="xs" onClick={() => onOrderSeperately(false)}>
										Order together
									</Button>
								)}
							</div>
						</div>
					</div>
					<BasicTable className="mt-3" table={table} />
					<BasicTable.Subtotal subtotal={subtotal} />
					<div className="mt-3 flex flex-col gap-3 empty:hidden">
						{model.estimator_notes && (
							<ChatBubble
								label="Estimator"
								notes={model.estimator_notes}
								className="self-start w-full max-w-lg"
							/>
						)}
						{model.vendor_notes && (
							<ChatBubble
								label="Supplier"
								notes={model.vendor_notes}
								className="w-full max-w-lg self-end text-right"
							/>
						)}
					</div>
				</OrderRequestSection.Content>
				<OverrideRejectionModal
					item={targetIndex ? model.items[targetIndex] : null}
					onClose={() => setTargetIndex(null)}
					onOverride={onOverride}
				/>
			</OrderRequestSection>
		);
	}
);

type ItemActions = {
	canRemove: boolean;
	overrideReject: boolean;
	orderSeperately: boolean;
	orderTogether: boolean;
};

const useAllowedActions = (selection: Record<number, boolean>, items: DraftOrderItemModel[]) => {
	const actions = useMemo<ItemActions>(() => {
		const selectedKeys = Object.keys(selection).map(key => Number(key));
		if (selectedKeys.length === 0) {
			return {
				canRemove: false,
				overrideReject: false,
				orderSeperately: false,
				orderTogether: false
			};
		}

		const selectedItems = selectedKeys.map(key => items[key]);

		// You can mark many items as not wanted, but only if they
		// are not already rejected
		const canRemove = selectedItems.every(item =>
			match(item.status, {
				Pending: () => true,
				Approved: () => true,
				Rejected: () => false
			})
		);

		// You can only override one item at a time, and only
		// under certain conditions
		const overrideReject =
			selectedItems.length === 1 &&
			match<draft_order.DraftOrderItemStatus, boolean>(selectedItems[0].status, {
				Pending: () => false,
				Approved: () => false,
				Rejected: arg =>
					match<draft_order.DraftOrderItemRejectedReason, boolean>(arg.reason, {
						Estimator: () => false,
						Supplier: arg =>
							// An estimator can only override these reasons
							arg === 'NotNeeded' || arg === 'IncorrectFitment'
					})
			});

		// You can order seperatly if you have more than one item, but less
		// that all of the items, and all of the items are either pending or approved
		const orderSeperately =
			items.length > 1 &&
			selectedItems.length < items.length &&
			selectedItems.every(
				item =>
					!item.order_separately &&
					match(item.status, {
						Pending: () => true,
						Approved: () => true,
						Rejected: () => false
					})
			);

		const orderTogether = selectedItems.every(item => item.order_separately === true);

		return { canRemove, overrideReject, orderSeperately, orderTogether };
	}, [selection, items]);

	return actions;
};

type OverrideRejectionModalProps = {
	item: DraftOrderItemModel | null;
	onClose: () => void;
	onOverride: (reason: string) => void;
};

const OverrideRejectionModal = ({ item, onClose, onOverride }: OverrideRejectionModalProps) => {
	const form = useForm({
		defaultValues: {
			reason: ''
		}
	});

	return (
		<StandardDialog open={isDefined(item)} onClose={onClose}>
			<StandardDialog.Title>Override rejection</StandardDialog.Title>
			<StandardDialog.Content>
				<p className="text-sm text-gray-700">
					This part was rejected by the supplier. You can choose to override this rejection, but
					this will affect returns.
				</p>
				<form noValidate className="mt-4">
					<Textarea label="Reason" required {...form.register('reason', { required: true })} />
					<div className="flex items-center justify-end gap-3 mt-4">
						<Button variant="default" onClick={onClose}>
							Cancel
						</Button>
						<Button onClick={form.handleSubmit(({ reason }) => onOverride(reason))}>Confirm</Button>
					</div>
				</form>
			</StandardDialog.Content>
		</StandardDialog>
	);
};
