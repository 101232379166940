import { useMeasurement } from '@/app/hooks/use-measure';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { entries, sortBy } from 'lodash-es';
import { Fragment, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
	CategoryTreeLeaf,
	Diagram,
	DiagramPartSlot,
	PartsFormData,
	PartsSelection
} from '../../types';
import { PartsHotpot } from '../parts-hotspot';

type PartsCartProps = InheritableElementProps<
	'div',
	{
		open?: boolean;
		categories: CategoryTreeLeaf[];
		diagram?: Diagram | null;
		partSlot?: DiagramPartSlot | null;
		control: Control<PartsFormData>;
		selection: PartsFormData;
		form: string;
		changed?: boolean;
		actions: {
			close: () => void;
			part: {
				highlight: (partSlotId: string) => void;
				jump: (category: string, diagram: string, partSlotId: string) => void;
			};
		};
	}
>;

export const PartsCart = ({
	open,
	control,
	selection,
	categories,
	diagram,
	partSlot,
	form,
	changed,
	actions,
	className,
	...rest
}: PartsCartProps) => {
	const { value: nav } = useMeasurement('navigation-bar');

	const shotcuts = useMemo(
		() =>
			categories.flatMap(({ id: category, diagrams }) =>
				diagrams.flatMap(({ id: diagram, description, partSlots }) =>
					partSlots.flatMap(({ id: partSlot, assemblies }) => ({
						category,
						diagram,
						description,
						partSlot,
						assemblies
					}))
				)
			),
		[categories]
	);

	const added = useMemo(() => {
		const values = sortBy(
			entries(selection).filter(([, s]) => s && s.quantity > 0),
			([, { order }]) => order
		);

		return values.map(([id, assembly]) => {
			const other = shotcuts.find(({ assemblies }) =>
				assemblies.some(({ id }) => id === assembly.assemblyId)
			);

			const slot = diagram?.partSlots.find(({ assemblies }) =>
				assemblies.some(({ id }) => id === assembly.assemblyId)
			);

			const highlighted = !!slot && !!partSlot && slot.id === partSlot.id;

			return [id, { ...assembly, other, slot, highlighted }] as const;
		});
	}, [selection, shotcuts]);

	return (
		<div
			className={tlsx(
				'flex flex-col h-full transition-all w-0 overflow-hidden',
				{ 'w-80 border-l': open },
				className
			)}
			{...rest}
		>
			<div
				className="flex flex-col w-80 overflow-auto h-full"
				style={{
					height: `calc(100dvh - ${nav?.height}px)`
				}}
			>
				<span className="text-lg font-semibold text-gray-900 mt-4 pb-2.5 px-3 border-b border-gray-200/50">
					{added.length} items selected
				</span>
				{added.length === 0 && (
					<span className="text-sm text-gray-600 px-3">
						Select a part from the diagram to view parts in the cart
					</span>
				)}
				<div className="flex flex-1 flex-col max-h-full gap-2 py-0.5 px-3 overflow-auto">
					{added.map(([id, { slot, highlighted, other, ...assembly }]) => (
						<Controller
							key={id}
							control={control}
							name={id}
							render={({ field }) => (
								<div className="flex flex-col items-center py-3 h-fit border-b w-full gap-2">
									<div className="flex items-center justify-between w-full gap-2">
										<span className="text-sm font-normal text-gray-900 truncate">
											{assembly.description}
										</span>
										<button
											type="button"
											className="text-xs font-semibold text-red-600"
											onClick={() => {
												const data: PartsSelection = {
													...field.value,
													quantity: 0
												};
												field.onChange(data);
											}}
										>
											Remove
										</button>
									</div>

									<div className="flex items-center flex-wrap gap-1 w-full empty:hidden">
										{assembly.hcas.map((hca, index) => (
											<Fragment key={`${hca}-${index}`}>
												<span className="text-xs leading-none text-gray-600">{hca}</span>
												<ChevronRightIcon className="w-2 h-2 last:hidden" />
											</Fragment>
										))}
									</div>

									<div className="w-full empty:hidden">
										{!assembly.assemblyId && (
											<span className="text-xs leading-none text-gray-600">
												Custom-created part
											</span>
										)}
									</div>

									<div className="flex items-center flex-wrap gap-2 w-full mt-1 empty:hidden">
										{slot && (
											<>
												<span className="text-xs leading-none text-gray-600">
													Shown in current diagram as{' '}
												</span>
												<PartsHotpot
													className="p-0.5 h-[3ch] min-w-[3ch] text-xs"
													code={slot?.code ?? null}
													highlighted={highlighted}
													onClick={() => actions.part.highlight(slot.id)}
												/>
											</>
										)}
										{!slot && other && (
											<button
												type="button"
												className="w-fit text-start text-xs leading-none text-gray-600 underline hover:text-blue-600 active:text-blue-600"
												onClick={() =>
													actions.part.jump(other.category, other.diagram, other.partSlot)
												}
											>
												Shown in {other.description}
											</button>
										)}
									</div>
								</div>
							)}
						/>
					))}
				</div>
				<div className="w-full mt-auto p-3 bg-white outline-none border-t border-gray-200/50">
					<Button
						type="submit"
						form={form}
						className="w-full"
						disabled={!changed && added.length === 0}
					>
						{changed ? (added.length === 0 ? 'Save' : 'Save & Next') : 'Next'}
					</Button>
				</div>
			</div>
		</div>
	);
};
